<template>
  <div class="relative flex-grow">
    <div class="wrapper">
      <h1 class="flex justify-center px-6 mb-4 text-3xl uppercase font-medium">
        Organization: Inactive
      </h1>
      <div class="text-center text-white text-lg">
        <p class="mb-8">
          Your organization is not currently active.
          Either you have just signed up and a human needs to activate your account,
          or your trial has expired and your account has been deactivated. You'll have
          to contact us to continue.
        </p>
        <Button
          type="primary"
          @mouseover.native="isButtonHovered = true"
          @mouseleave.native="isButtonHovered = false"
          @onClick="contactSupport">
          Contact Support
        </Button>
      </div>
      <div class="power-up-bg"></div>
      <div class="power-up"
           :class="{'power-up--hover': isButtonHovered}"></div>
      <Circuits class="absolute top-0 left-1/2 transform -translate-x-1/2 h-full -z-1" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import Circuits from '@/assets/circuits-bg.svg?inline';

export default {
  name: 'InactiveOrganization',
  components: {
    Button,
    Circuits,
  },
  data() {
    return {
      isButtonHovered: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    contactSupport() {
      const EMAIL = 'orbital@phobos.io';
      const SUBJECT = 'Reactivate Organization';
      const BODY = 'I\'d like to activate my Orbital subscription.';

      // eslint-disable-next-line no-restricted-globals
      location.href = `mailto:${EMAIL}?subject=${SUBJECT}&body=${BODY}`;
    },
  },
};
</script>

<style scoped>
.wrapper {
  @apply flex flex-col justify-center mt-32 mx-auto w-full;
  max-width: 400px;
}

.power-up-bg {
  @apply absolute top-0 left-1/2 transform -translate-x-1/2 h-full -z-1;
  background:
    linear-gradient(to top, #251F1C 0%, #251F1C 90%, #151010 100%) 0 0;
  width: 1000px;
}
.power-up {
  @apply absolute top-0 left-1/2 transform -translate-x-1/2 h-full -z-1 bg-blue-100 scale-y-0
         opacity-0 origin-bottom;
  background:
    linear-gradient(to top, #00A4FF 0%, #00A4FF 90%, #151010 100%) 0 0;
  transition: transform 300ms ease-in 100ms, opacity 100ms linear;
  width: 1000px;
}
.power-up--hover {
  @apply scale-y-100 opacity-100;
}
</style>
